var VanillaToasts = require("vanillatoasts");

var form = document.getElementById("contact-form");

async function handleSubmit(event) {
  event.preventDefault();
  var data = new FormData(event.target);

  if (
    !data.get("email") ||
    !data.get("name") ||
    !data.get("budget") ||
    !data.get("message")
  ) {
    VanillaToasts.create({
      text: "All fields are required",
      type: "warning",
      timeout: 2000,
    });
    return;
  }

  const payload = {
    email: data.get("email"),
    message: `Name: ${data.get("name")}\
      Budget: ${data.get("budget")}
      Message: ${data.get("message")}`,
  };

  fetch(event.target.action, {
    method: form.method,
    body: JSON.stringify(payload),
    headers: {
      Accept: "application/json",
    },
  }).then((response) => {
    if (response.ok) {
      form.reset();

      VanillaToasts.create({
        text: "Message has succesfully sent!",
        type: "success",
        timeout: 2000,
      });
    }
  });
}
form.addEventListener("submit", handleSubmit);
